.Selection input[type="text"],
.Selection input[type="text"]:focus {
  box-shadow: none;
  height: 18px;
  line-height: 18px;
}
.Selection :global .Select__control {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  min-height: 30px;
}
.Selection :global .Select__control--is-focused {
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(82, 168, 236, 0.6);
  border-color: rgba(82, 168, 236, 0.8);
}
.Selection :global .Select__placeholder {
  font-size: 13px;
  color: rgb(164, 164, 164);
}
.Selection :global .Select__multi-value__label {
  padding-top: 1px;
  padding-bottom: 1px;
}
.Selection :global .Select__dropdown-indicator,
.Selection :global .Select__clear-indicator {
  padding: 4px;
}
.Selection :global .Select__value-container {
  padding: 0 6px;
}
.Selection :global .Select__input {
  margin: 0;
  padding: 0;
}
.Selection :global .Select__indicator-separator {
  margin: 4px 0;
}
