@import "./typography.css";
@import "./backgrid-stuff.css";
@import "./tailwind.css";
@import "./fonts/Inter Web/inter.css";
@import "./legacy_components/index.css";
@import "./components/index.css";
@import "./utilities.css";
@import "./header.css";
@import "./module-switcher.css";
@import "./userbar.css";
@import "./chatbar.css";
@import "./mobile_app_chat.css";
@import "./composer.css";
@import "./z.css";
@import "./print.css";

.tixxt {
  min-height: 100vh;
  max-width: 100vw;
  display: grid;
  color: var(--normal-text);

  grid:
    "header" auto
    "navigation" auto
    "main" 1fr
    / 1fr;

  /* Inter + default tailwind sans fontstack (https://github.com/tailwindlabs/tailwindcss/blob/master/src/css/preflight.css#L32) */
  font-family:
    "Inter",
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";

  /* wow */
  @apply antialiased;

  /* see https://rsms.me/inter/#features/cvXX */
  font-feature-settings: "cv02", "cv03", "cv04", "cv07", "cv09", "cv11";
}

.tixxt--wide .tixxt__userbar {
  display: none;
}

@supports (font-variation-settings: normal) {
  .tixxt {
    /* Inter as variable font if supported */
    font-family:
      "Inter var",
      ui-sans-serif,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      "Noto Sans",
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji";
  }
}

.tixxt__header {
  grid-area: header;
  height: calc(var(--header-topbar-height) + var(--header-banner-height));
}

.tixxt__navigation,
.tixxt__navigation_fill {
  position: sticky;
  top: var(--navigation-offset);
  grid-area: navigation;
  background-color: var(--navigation-background);
  /* Does not overlap the usercentral menu but overlaps buttons, inputs etc. */
  min-height: 2.5rem; /* 40px; same as overlap of extended logo + image banner header, so that it aligns perfectly when scrolled down */
  /* applied here because backend rendered */
  @apply flex flex-wrap items-center space-x-3 gap-1 justify-end mb-4 px-6 py-2 text-sm;
}

.tixxt__navigation:empty,
.tixxt__navigation:empty ~ .tixxt__navigation_fill {
  visibility: hidden;
}

.tixxt__navigation_fill {
  display: none;
  @apply px-0;
}

.tixxt__navigation_link {
  color: var(--navigation-text, #71717a);
}

.tixxt__navigation_link:hover {
  color: var(--navigation--hover-text, var(--link--hover-text));
}

.tixxt__content {
  grid-area: main;
  max-width: 100vw;
  overflow-x: auto;
  @apply px-6 xl:px-12 pb-8;
}

.tixxt__spacer {
  background-color: var(--header-background);
  display: none;
}

.tixxt__pagetitle {
  @apply space-x-4 mb-8 mt-4 items-baseline flex;
}

.tixxt__pagetitle h1 {
  @apply font-semibold text-xl;
}

.tixxt__subtitle {
  @apply uppercase font-semibold text-sm mb-3 mt-8;
}

/* slightly round corners for all images to guarantee consistency */
img {
  @apply rounded;
}

/* round profile pictures */
.member-image > img,
.membership > img,
.members-list > li > a > img,
.member-link > img,
img.member-image {
  border-radius: 100%;
}

.flashes {
  position: absolute;
  left: 5px;
  right: 5px;
  top: 5px;
}

#toast-container {
  position: fixed;
  max-width: 400px;
  right: 25px;
  bottom: 25px;
  z-index: 1004;
}

@media (min-width: 640px) {
  .flashes {
    position: absolute;
    left: 30%;
    right: 30%;
    top: 5px;
  }
}

/* Larger than small screen (tailwind "lg:", alternative 800px)*/
@media screen and (min-width: 1024px) {
  .tixxt {
    grid:
      "header header" auto
      "userbar navigation" auto
      "userbar main" 1fr
      / min-content 1fr;
  }

  .tixxt--wide {
    grid:
      "header header" auto
      "navigation" auto
      "main" 1fr
      / min-content 1fr;
  }

  .tixxt_content {
    max-width: calc(100vw - 250px);
  }

  .tixxt__userbar {
    grid-area: userbar;
    position: sticky;
    top: max(var(--header-logo-height), var(--navigation-offset));
    align-self: flex-start;
  }

  .userbar__content {
    height: calc(
      100vh - max(var(--header-logo-height), var(--navigation-offset))
    );
  }
}

/* Larger than max content width (tailwind "2xl:", alternative 1440px) */
@media screen and (min-width: 1536px) {
  body {
    --userbar-width: var(--override-userbar-width, 280px);
  }

  .tixxt {
    grid:
      "header header header header" auto
      "fillstart userbar navigation nav-fill" auto
      "fillstart userbar main fillend" 1fr
      / 1fr var(--userbar-width) calc(1536px - var(--userbar-width)) 1fr;
  }

  .tixxt--wide {
    grid:
      "header header header" auto
      "navigation navigation nav-fill" auto
      "fillstart main fillend" 1fr
      / 1fr 1536px 1fr;
  }

  .tixxt--full {
    grid:
      "header header header header" auto
      "userbar navigation navigation navigation" auto
      "userbar fillstart main fillend" 1fr
      / var(--userbar-width) 1fr calc(1536px - var(--userbar-width)) 1fr;
  }

  .tixxt--full.full-content {
    grid:
      "header header" auto
      "userbar navigation" auto
      "userbar main" 1fr
      / 280px 1fr;
  }

  .tixxt__spacer {
    display: block;
  }

  .tixxt__navigation_fill {
    display: block;
    grid-area: nav-fill;
  }
}

.accept-invite-text {
  color: #28a745;
}

/* #1165 needed to make the tixxt page creator templates working again */

.page-creator h4 {
  margin-top: 0.5em;
}

.page-creator .navbar-inner {
  @apply bg-gray-100;
}

.page-creator .label {
  @apply bg-gray-200;
}

/* #1220 needed to show a placeholder box when dragging a field while using the app creator  */

#app-properties .response-field-draggable-helper {
  @apply border-box bg-gray-100 cursor-move;
}

/* tixxt in app styles */
@media screen and (max-width: 1023px) {
  .tixxt--in-app .tixxt__header .header__content,
  .tixxt--in-app .tixxt__header .header__banner,
  .tixxt--in-app .tixxt__pagetitle {
    display: none;
  }

  .tixxt--in-app #main-navigation.tixxt__navigation {
    top: 0;
  }

  .tixxt--in-app .tixxt__header {
    height: 0;
  }

  .tixxt--in-app .tixxt__header .header__content.header--visible {
    display: flex;
    @apply justify-items-center;
  }

  .tixxt--in-app .tixxt__content {
    @apply p-6;
  }
}
