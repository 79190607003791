/*

DO NOT EDIT THIS FILE MANUALLY !

It is can be regenerated by running `scripts/dev/frontend_prebuild.sh`
after changing rules or variables of the design manager or default theme.

Generator source:
  app/javascript/components/administration/designManager/exportDefaultTheme.ts

Rules:
  app/javascript/components/administration/designManager/rules.ts

Default Theme:
  app/javascript/components/administration/designManager/defaultTheme.ts

*/
:root {
  --tailwind-primary: 153 190 218;
  --tailwind-secondary: 65 44 124;
  --tailwind-white: 255 255 255;
  --tailwind-favorite: 252 211 77;
  --tailwind-info: 2 132 199;
  --tailwind-success: 34 197 94;
  --tailwind-warning: 250 204 21;
  --tailwind-danger: 220 38 38;
  --gray-50: #fafafa;
  --gray-100: #f4f4f5;
  --gray-200: #e4e4e7;
  --gray-300: #d4d4d8;
  --gray-400: #a1a1aa;
  --gray-500: #71717a;
  --gray-600: #52525b;
  --gray-700: #3f3f46;
  --gray-800: #27272a;
  --gray-900: #18181b;
  --tailwind-neutral: 212 212 216;
  --normal-text: #27272a;
  --tailwind-normal-text: 39 39 42;
  --link-text: #215073;
  --link--hover-text: #0b3351;
  --unread__content-background: #f0ebff;

  --btn-primary-background: #99beda;
  --btn-primary-text: #27272a;
  --btn-primary-border: #99beda;
  --btn-primary--hover-background: #82a2b9;
  --btn-primary--hover-border: #738fa4;
  --btn-primary--hover-text: #27272a;
  --btn-light-background: #ffffff;
  --btn-light-text: #27272a;
  --btn-light-border: #d4d4d8;
  --btn-light--hover-background: #f4f4f5;
  --btn-light--hover-border: #a1a1aa;
  --btn-light--hover-text: #27272a;
  --btn-danger-background: #dc2626;
  --btn-danger-text: #ffffff;
  --btn-danger-border: #dc2626;
  --btn-danger--hover-background: #bb2020;
  --btn-danger--hover-border: #a51d1d;
  --btn-danger--hover-text: #ffffff;
  --alert-info-background: #d4e9f4;
  --alert-info-border: #94bcd0;
  --alert-info-text: #002233;
  --alert-success-background: #d4f4df;
  --alert-success-border: #94d0aa;
  --alert-success-text: #003313;
  --alert-warning-background: #f4edd4;
  --alert-warning-border: #d0c494;
  --alert-warning-text: #332900;
  --alert-danger-background: #f4d4d4;
  --alert-danger-border: #d09494;
  --alert-danger-text: #330000;
  --tag-background: #d4e6f4;
  --tag-text: #0b3351;
  --tag--reader-background: #dcd4f4;
  --tag--reader-text: #1d0b51;

  --module-switcher-background: #e4e4e7;
  --module-switcher-text: #71717a;
  --module-switcher--hover-background: #e0e2e6;
  --module-switcher--hover-text: #8598aa;
  --module-switcher--active-background: #99beda;
  --module-switcher--active-text: #000000;
  --unread-counter-background: #412c7c;
  --unread-counter-text: #ffffff;

  --userbar-background: #f4f4f5;
  --userbar-section-background: #ffffff;
  --userbar-section__title-text: #27272a;
  --userbar-section__indicator-color: #71717a;
  --userbar-link-text: #27272a;
  --userbar-link--hover-background: #fafcfd;
  --userbar-link--hover-text: #607382;
  --userbar-link--active-background: #99beda;
  --userbar-link--active-text: #27272a;
  --chat-accent: #99beda;
  --chat-accent-contrast: #27272a;
  --chat-background: #f4f4f5;
  --chat-text: #27272a;
  --tailwind-chat-text: 39 39 42;
  --chat-header-background: #e4e4e7;
  --chat-header-text: #27272a;
  --tailwind-chat-header-text: 39 39 42;
  --chat-list--hover-background: #eff1f4;
  --chat-message-background: #ffffff;
  --chat-message-text: #27272a;
  --tailwind-chat-message-text: 39 39 42;
  --chat-message--my-background: #e5f2fc;
  --chat-message--my-text: #27272a;
  --tailwind-chat-message--my-text: 39 39 42;
  --chat-composer-background: #ffffff;
  --chat-composer-text: #27272a;
  --tailwind-chat-composer-text: 39 39 42;
  --calendar-today-background: #e5f2fc;
  --header-topbar-background: #27272a;
  --header-topbar-contrast: #ffffff;
  --header-topbar-contrast--hover: #bfbfbf;
  --header-logo-background: #ffffff;
  --search-bar__input-background: #27272a;
  --search-bar__input-color: #ffffff;
  --search-bar__input-border-color: #bfbfbf;
  --search-bar__input--hover-border: #bfbfbf;
  --search-bar__input--focus-background: #ffffff;
  --search-bar__input--focus-color: #27272a;
  --search-bar__input--focus-border-color: #ffffff;
  --navigation-background: #fafafa;
  --navigation-text: #71717a;
  --navigation--hover-text: #93939a;
  --border-box-background: #ffffff;
  --border-box-border: #d4d4d8;
  --question-accent: #412c7c;
  --question-background: #ebe5fc;
  --question-border: #c4b8e5;
  --question-text: #0d0033;
  --question--question-mark: #7e6bb6;
  --question-answer-background: #ebe5fc;
  --question-answer-text: #0d0033;
  --mobile-app-tab-bar: #e4e4e7;
  --mobile-app-tab-bar-item: #71717a;
  --mobile-app-tab-bar-item-active: #000000;
  --mobile-app-tab-bar-item-active-bg: #99beda;
  --mobile-app-navigation-bar: #27272a;
  --mobile-app-navigation-bar-text: #ffffff;
}
:root {
  --header-logo-height: 0px;
  --header-logo-display: none;
  --header-home-icon-display: grid;
  --header-small-search-display: flex;
  --header-small-grid: "burger home-icon search user-central";
  --navigation-offset: 60px;
}
:root {
  --header-banner-height: 0;
  --header-banner-position: unset;
  --header-banner-background: transparent;
}
