#toast-container,
.flashes {
  z-index: 1100;
}

/*
See https://getbootstrap.com/docs/5.1/layout/z-index/
$zindex-tooltip:                    1080;
$zindex-popover:                    1070;
$zindex-modal:                      1055;
$zindex-modal-backdrop:             1050;
$zindex-offcanvas:                  1045;
$zindex-offcanvas-backdrop:         1040;
$zindex-fixed:                      1030;
$zindex-sticky:                     1020;
$zindex-dropdown:                   1000;
 */
.tixxt__userbar,
.chatbar {
  z-index: 500;
}

.tixxt__header {
  z-index: 450;
}

.tixxt__navigation,
.tixxt__navigation_fill {
  z-index: 400;
}

.header__banner {
  z-index: -1;
}

#back-to-top {
  z-index: 501;
}
