.ReplyComposer :global .property-content {
  margin-bottom: -1px;
}

.ReplyComposer :global .Composer {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 6px;
  padding-right: 6px;
}

.ReplyComposerFormActions {
  margin-top: 1em;
}

.Attachments {
  border: 1px solid #ddd;
  background-color: #fff;
}

.AttachmentsSelector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.AttachmentsSelectorItem {
  border-right: 1px solid #ddd;
  background-color: #ffffff;
  padding: 5px 10px;
  color: #006dce;
  cursor: pointer;
}

.AttachmentsSelectorItem:hover {
  text-decoration: underline;
}

.FileUpload {
  composes: File from "../../appCreator/properties/edit/editFields.module.css";
}

:global(.Composer).closed {
  width: 100%;
  text-align: start;
  padding: 6px;
  border-radius: 0;
}
